import './widgetsWhatsapp.pcss';
const iconPath1 = 'M11.4662 3.95337C10.4779 3.11044 9.21603 2.65714 7.91729 2.67854C6.61854 ' +
    '2.69994 5.37225 3.19457 4.41229 4.0696C3.45234 4.94464 2.84472 6.13992 2.70346 ' +
    '7.43114C2.5622 8.72235 2.89701 10.0207 3.64506 11.0826C3.77896 11.2727 3.80416 11.5188 ' +
    '3.71156 11.7321L3.10651 13.1255L5.24975 12.7436C5.3891 12.7187 5.53274 12.7389 5.65987 ' +
    '12.8011C6.82647 13.3723 8.16141 13.4982 9.41418 13.155C10.667 12.8118 11.7514 12.0233 12.4641 ' +
    '10.9373C13.1767 9.85135 13.4686 8.54264 13.2849 7.25677C13.1011 5.97091 12.4545 4.7963 11.4662 ' +
    '3.95337ZM7.89532 1.34539C9.51875 1.31864 11.0961 1.88526 12.3315 2.93892C13.5668 3.99259 14.3751 ' +
    '5.46085 14.6048 7.06818C14.8345 8.67551 14.4696 10.3114 13.5788 11.6688C12.688 13.0263 11.3324 14.012 ' +
    '9.76644 14.441C8.27227 14.8502 6.68459 14.7258 5.27542 14.0933L2.11702 14.6562C1.8752 14.6993 1.62928 ' +
    '14.606 1.47699 14.4132C1.3247 14.2205 1.29071 13.9597 1.38854 13.7344L2.34451 11.5327C1.55796 10.2685 ' +
    '1.21531 8.77351 1.37803 7.28613C1.55461 5.67211 2.31414 4.17801 3.51408 3.08422C4.71402 1.99043 6.27189 ' +
    '1.37214 7.89532 1.34539Z';
const iconPath2 = 'M5.62627 5.29289C5.8138 5.10536 6.06816 5 6.33337 5C6.59859 5 6.85294 5.10536 7.04048 5.29289C7.22802 ' +
    '5.48043 7.33337 5.73479 7.33337 6V6.66667C7.33337 6.93188 7.22802 7.18624 7.04048 7.37377C6.97304 7.44121 ' +
    '6.89697 7.49802 6.81486 7.54313C6.94581 7.91941 7.16068 8.26521 7.44776 8.55228C7.73484 8.83936 8.08063 ' +
    '9.05423 8.45692 9.18519C8.50202 9.10307 8.55883 9.027 8.62627 8.95956C8.8138 8.77202 9.06816 8.66667 ' +
    '9.33337 8.66667H10C10.2653 8.66667 10.5196 8.77202 10.7071 8.95956C10.8947 9.1471 11 9.40145 11 9.66667C11 ' +
    '9.93188 10.8947 10.1862 10.7071 10.3738C10.5196 10.5613 10.2653 10.6667 10 10.6667H9.33337C8.27251 10.6667 ' +
    '7.25509 10.2452 6.50495 9.49509C5.7548 8.74495 5.33337 7.72753 5.33337 6.66667V6C5.33337 5.73479 5.43873 ' +
    '5.48043 5.62627 5.29289Z';
const getHtml = (number) => `
  <a href='https://wa.me/${number}'
     target='_blank'
     title='Написать в Whatsapp'
     rel='noopener noreferrer'>
    <div class='whatsappButton'>
      <svg width='38'
           height='38'
           viewBox='0 0 16 16'
           fill='none'
           xmlns='http://www.w3.org/2000/svg'>
        <path fill-rule='evenodd'
              clip-rule='evenodd'
              d='${iconPath1}'
              fill='#FFF'/>
        <path fill-rule='evenodd'
              clip-rule='evenodd'
              d='${iconPath2}'
              fill='#FFF'/>
      </svg>
    </div>
  </a>
`;
const MetaclinincWidget = (number) => {
    const body = document.querySelector('body');
    const div = document.createElement('div');
    div.innerHTML = getHtml(number);
    if (body)
        body.appendChild(div);
};
window.MetaclinincWidget = MetaclinincWidget;
