// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whatsappButton {
  display: flex;
  position: fixed;
  right: 13px;
  bottom: 90px;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  z-index: 9999;
  border-radius: 50%;
  background: #25D366;
  width: 55px;
  height: 55px;
  line-height: 53px;
  color: #FFFFFF;
  font-size: 35px;
}

  .whatsappButton:before,
  .whatsappButton:after {
    display: block;
    position: absolute;
    inset: -20px;
    backface-visibility: hidden;
    opacity: 0;
    border: 1px solid #25D366;
    border-radius: 50%;
    animation: animate 1.5s linear infinite;
    content: ' ';
  }

  .whatsappButton:after {
    animation-delay: .5s;
  }

  .whatsappButton a {
    color: #FFFFFF;
  }

@keyframes animate {
  0% {
    transform: scale(.5);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./app/frontend/widgets/widgetsWhatsapp.pcss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,gCAAgC;EAChC,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,cAAc;EACd,eAAe;AAsBjB;;EApBE;;IAEE,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,2BAA2B;IAC3B,UAAU;IACV,yBAAyB;IACzB,kBAAkB;IAClB,uCAAuC;IACvC,YAAY;EACd;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,cAAc;EAChB;;AAGF;EACE;IACE,oBAAoB;IACpB,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;AACF","sourcesContent":[".whatsappButton {\n  display: flex;\n  position: fixed;\n  right: 13px;\n  bottom: 90px;\n  align-items: center;\n  justify-content: center;\n  transform: translate(-50%, -50%);\n  z-index: 9999;\n  border-radius: 50%;\n  background: #25D366;\n  width: 55px;\n  height: 55px;\n  line-height: 53px;\n  color: #FFFFFF;\n  font-size: 35px;\n\n  &:before,\n  &:after {\n    display: block;\n    position: absolute;\n    inset: -20px;\n    backface-visibility: hidden;\n    opacity: 0;\n    border: 1px solid #25D366;\n    border-radius: 50%;\n    animation: animate 1.5s linear infinite;\n    content: ' ';\n  }\n\n  &:after {\n    animation-delay: .5s;\n  }\n\n  & a {\n    color: #FFFFFF;\n  }\n}\n\n@keyframes animate {\n  0% {\n    transform: scale(.5);\n    opacity: 0;\n  }\n\n  50% {\n    opacity: 1;\n  }\n\n  100% {\n    transform: scale(1.2);\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
